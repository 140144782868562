import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "pb-5" }
const _hoisted_3 = { class: "d-flex align-items-center landingSubtitle" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "fs-5 text-gray-600 mb-5 textoJustificado" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mb-5" }
const _hoisted_8 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_9 = { class: "form-check-label required" }
const _hoisted_10 = {
  type: "button",
  class: "btn btn-active-light-primary botonPrivacidad shadow-none",
  "data-bs-toggle": "modal",
  "data-bs-target": "#condiciones-privacidad"
}
const _hoisted_11 = {
  type: "button",
  class: "btn btn-color-primary btn-active-light-primary text-uppercase mb-5",
  "data-bs-toggle": "modal",
  "data-bs-target": "#declaracion-responsable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_CondicionesPrivacidadModal = _resolveComponent("CondicionesPrivacidadModal")!
  const _component_DeclaracionResponsableModal = _resolveComponent("DeclaracionResponsableModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("paso5")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", {
            innerHTML: _ctx.$t('textoBodyPrivacidad')
          }, null, 8, _hoisted_6)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            name: "politicaPrivacidad",
            class: "form-check-input form-control form-control-solid",
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.politicaPrivacidad) = $event)),
            id: "politicaPrivacidad"
          }, null, 512), [
            [_vModelCheckbox, _ctx.politicaPrivacidad]
          ]),
          _createElementVNode("label", _hoisted_9, [
            _createTextVNode(_toDisplayString(_ctx.$t("aceptacion")), 1),
            _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.$t("PoliticaPrivacidad")), 1)
          ])
        ]),
        _createVNode(_component_ErrorMessage, {
          name: "politicaPrivacidad",
          class: "fv-plugins-message-container invalid-feedback"
        })
      ]),
      _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t("DeclaracionBtn")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "declaracionResponsable",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createVNode(_component_CondicionesPrivacidadModal, { id: "condiciones-privacidad" }),
    _createVNode(_component_DeclaracionResponsableModal, {
      id: "declaracion-responsable",
      name: "declaracionResponsable",
      modelValue: _ctx.declaracionResponsable,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.declaracionResponsable) = $event)),
      html: 
      _ctx.$t('DeclaracionResponsableBodyFamilia', {
        nombre: _ctx.nombre.toUpperCase(),
        apellidos: _ctx.apellidos.toUpperCase(),
        dni: _ctx.dni.toUpperCase(),
        direccion: _ctx.direccion.toUpperCase(),
        localidad: _ctx.localidad.toUpperCase(),
        dia: _ctx.dia.toUpperCase(),
      })
    
    }, null, 8, ["modelValue", "html"])
  ], 64))
}